<template>
    <section class="main-content-section faq">
        <div class="grid">
            <div class="col-1-1">
                <h1 class="page-title">{{frequentlyAskedQuestions}}</h1>
            </div>            

            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="faq-search">
                    <h2 class="subtitle">{{faqSearchText}}</h2>

                    <div class="row">
                        <form class="flex align-center search-wrap" @submit.prevent="search()">
                            <div class="input-container">
                                <div class="input">
                                    <input 
                                        type="search" 
                                        :placeholder="searchInputPlaceholder"
                                        name="queryText" 
                                        id="queryText" 
                                        @keydown="getFaqItems()" 
                                        @keyup="search()" 
                                        v-model.trim="queryText" 
                                    />
                                </div>
                            </div>
                                    
                            <div class="search-btn-container">
                                <div>
                                    <button type="submit" class="btn btn-secondary">{{searchButton}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <template v-if="showResults">
                <FaqItem 
                    v-for="(faq, i) in faqItems" 
                    :key="`faq-${i}`" 
                    :title="faq[`title${selectedLanguage}`]" 
                    :text="faq[`text${selectedLanguage}`]"
                    :display="faq.display" 
                />
            </template>

            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="faq-empty"><p>{{ noResultsFound }}</p></div>
            </div>
        </div>
    </section>
</template>

<script>
import FaqItem from '@/components/FaqItem';
import agent from '@/api/agent';
import { mapGetters } from 'vuex';

export default {
    name: 'Faq',

    components: { FaqItem },

    data() {
        return {
            faqItems: [],
            showResults: false,
            queryText: ''
        }
    },

    created() {
        this.setDocumentTitle();
        this.getFaqItems();
    },

    updated() {
        this.setDocumentTitle();
    },

    computed: {
        ...mapGetters(['selectedLanguage', 'frequentlyAskedQuestions', 'faqSearchText', 'searchInputPlaceholder', 'searchButton', 'noResultsFound', 'metaTitle'])
    },

    methods: {
        search() {
            this.faqItems.forEach(faq => {
                const isPresentInTitle = faq[`title${this.selectedLanguage}`].toLowerCase().includes(this.queryText.toLowerCase());
                const isPresentInText = faq[`text${this.selectedLanguage}`].toLowerCase().includes(this.queryText.toLowerCase());

                if (!isPresentInTitle && !isPresentInText) {
                    faq.display = false;
                }
            });

            const noResultsFound = !this.faqItems.map(faq => faq.display).includes(true);
            
            if (noResultsFound) {
                this.showResults = false;
            }
        },

        async getFaqItems() {
            localStorage.getItem('token');
            try {
                const items = await agent.pages.faq();

                if (items.length) {
                    this.showResults = true;
                }

                this.faqItems = items.map(item => {
                    return {...item, display: true};
                });
            } catch (error) {
                console.log(error)
            }
        },
        
        setDocumentTitle() {
            document.title = this.frequentlyAskedQuestions + this.metaTitle;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.faq {
    background-color: $grey-light-1;
    padding: 100px 0;
}

.faq-search {
    background-color: $white;
    padding: 2.5rem;
    border-radius: 8px;
    box-shadow: $shadow-1;
    margin: 2rem 0 1rem 0;
    
    .subtitle {
        font-size: 2rem;
        font-weight: 300;
        line-height: normal;
        margin: 0 0 1rem 0;
    }

    .input-container {
        flex: 1;
    }

    .search-btn-container {
        margin-left: 2rem;
    }
    
    .input input {
        background-color: $offwhite;
        border: 1px solid $grey-light-6;
        border-radius: 8px;
        width: 100%;
        padding: 1.7rem;
        box-sizing: border-box;
    }

    button {
        display: block;
        width: 100%;
        text-align: center;
    }
}

.faq-empty {
    background-color: $white;
    padding: 2.5rem;
    border-radius: 8px;
    box-shadow: $shadow-1;
    font-size: 1.8rem;
    text-align: center;
}

@media only screen and (max-width: 450px) {
    .subtitle {
        text-align: center;
    }

    .search-wrap {
        flex-direction: column;
    }

    .input-container {
        flex: 1;
        width: 100%;
        margin-bottom: 1rem;
    }

    .search-btn-container {
        margin-left: 0;
        // width: 100%;
    }
}
</style>